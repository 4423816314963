var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-around" },
        [
          _c(
            "v-menu",
            { attrs: { activator: "#gpio-menu-activator" } },
            [
              _c(
                "v-list",
                _vm._l(_vm.gpioMenuItems, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { value: index },
                      on: {
                        click: function ($event) {
                          return _vm.handleGPIOMenuClick(index)
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-menu",
            { attrs: { activator: "#interface-menu-activator" } },
            [
              _c(
                "v-list",
                _vm._l(_vm.interfaceMenuItems, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { value: index },
                      on: {
                        click: function ($event) {
                          return _vm.handleInterfaceMenuClick(index)
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-menu",
            { attrs: { activator: "#i2c-menu-activator" } },
            [
              _c(
                "v-list",
                _vm._l(_vm.i2cMenuItems, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { value: index },
                      on: {
                        click: function ($event) {
                          return _vm.handleI2CMenuClick(index)
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Scan", { attrs: { id: "viewScanner" } }),
      _c("DataTable", {
        ref: "table",
        attrs: {
          id: "topTemplate",
          "default-options": {
            sortBy: ["lastModifyTime"],
            sortDesc: [true],
          },
          headers: _vm.headers,
          "load-data": _vm.loadData,
          "item-key": "id",
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.number`,
              fn: function ({ index }) {
                return [_vm._v(" " + _vm._s(index + 1) + " ")]
              },
            },
            {
              key: "headerButtons",
              fn: function () {
                return [
                  _c(
                    "v-tabs",
                    _vm._l(_vm.InterfaceItems, function (tabs) {
                      return _c(
                        "v-tab",
                        {
                          key: tabs.tab,
                          attrs: { id: tabs.id, title: tabs.tab },
                          on: {
                            click: function ($event) {
                              return _vm.HandleInterfaceSelect(tabs.tab)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(tabs.tab) + " ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row pb-1 px-2" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { depressed: "", tile: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAdd(_vm.tag)
                            },
                          },
                        },
                        [_vm._v(" Add item ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { depressed: "", tile: "" },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(" Add item ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.testGetMenu(item.IP)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" rowing ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("connect")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(item)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("information")])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("vue-grid-sheet", {
        ref: "grid",
        attrs: {
          id: "gridTemplate",
          data: _vm.demoBasic.data,
          format: _vm.demoBasic.format,
          state: _vm.demoBasic.state,
        },
      }),
      _c("InterfaceSchema", {
        ref: "InterfaceSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
      _c("GPIOSchema", {
        ref: "GPIOSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
      _c("I2CSchema", {
        ref: "I2CSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }